<template>
    <div class="wrap">
        <div class="list_box">
            <div class="title">
                商户对账
            </div>
            <div class="merchant_box">
                <div>商户编号：{{merchantNo}}</div>
                <div>商户名称：{{merchantName}}</div>
            </div>
            <div class="scroll_box">
                <van-list class="vant_list" v-model="loading"
                          :finished="finished"
                          finished-text="没有更多了"
                          @load="onLoad">
                    <div class="row" v-for="(item,index) in list" :key="item" >
                        <div class="month">
                            <div style="display: flex;justify-content: space-between">
                                <div>序号：{{index+1}}/{{item.totalNum}}</div>
                                <div class="detail" @click="showdetail(item)">详情</div>
                            </div>
                            <div>优惠券名称：{{item.couponName}}</div>
                            <div>订单金额（元）：{{item.totalOrderAmount|curreny}}/{{item.totalOrderAmountTotal|curreny}}</div>
                            <div>垫资应入金额：{{item.advancesAmount|curreny}}/{{item.advancesAmountTotal|curreny}}</div>
                            <template v-if="item.checked">
                                <div>收款人信息：{{item.receiverAccountName}}</div>
                                <div>收款人账号：{{item.receiverAccountNo}}</div>
                                <div>收款人开户行信息：{{item.receiverAccountBankName}}</div>
                                <div>报销周期：{{item.reimbursementCycle}}</div>
                                <div>核销时间：{{item.writeOffTime}}</div>
                            </template>
                        </div>
<!--                        <div class="detail" @click="todetail(item)">查看详情》</div>-->
                    </div>
                </van-list>
            </div>
        </div>
    </div>
</template>
<script>
    import {Toast} from 'vant'
 export default {
     name:'list',
     data(){
         return {
             merchantNo:"",
             merchantName:"",
             loading:false,
             finish:false,
             list:[],
             queryParams: {
                 pageNum: 1,
                 pageSize: 10,
                 merchantNo: null
             }
         }
     },
     filters:{
         curreny:function(data){
             data = Number(data).toFixed(2);
             return data;
         }
     },
     mounted(){
         this.queryParams.merchantNo =this.merchantNo = this.$route.query.merchantNo
         this.merchantName = this.$route.query.merchantName

     },
     methods:{
         getList(){
             this.$api.getList1(this.queryParams).then(res=>{
                 if(res.data.code===200)
                 {
                     this.loading = false;
                     res.data.rows.map(item=>{
                         item.checked = false
                     })
                     this.list.push(...res.data.rows)
                     if(this.list.length>=res.data.total)
                     {
                         this.finished = true
                     }
                 }else {
                     Toast(res.msg)
                 }
             })
         },
         onLoad(){
             this.getList();
             this.queryParams.pageNum++;
         },
         showdetail(item){
            item.checked = !item.checked
         }
     }
 }
</script>
<style scoped>
    .wrap {
        padding: 20px;
        box-sizing: border-box;
    }

    .list_box{
        border-radius: 10px;
        width: 100%;
        height: 100%;
        background: #f0f8fb;
        overflow: hidden;
        box-sizing: border-box;

    }
    .scroll_box{
        height: calc(100% - 48px);
        overflow-y: scroll;
        padding:20px 20px 40px 20px;
        box-sizing: border-box;
    }
    .title{
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .row{
        /*height: 48px;*/
        /*background: white;*/
        margin-bottom: 20px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 5px 5px;
        box-sizing: border-box;
    }
    .month>div{
        padding: 2px 0px;
    }
    .detail{
        color: #5186c5;
    }
    .merchant_box{
        padding:20px;
        border-bottom: 1px solid #000000;
    }
</style>
