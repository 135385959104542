import Vue from 'vue'
import Router from 'vue-router'
import index from '../components/index'
import home from '../components/home'
import list from '../components/list'
Vue.use(Router)
export default new Router({
    routes:[{
        path:"/",
        name:'登录页',
        component:index
    },
    {
        path:"/home",
        name:'首页',
        component:home
    },
    {
        path:"/list",
        name:'首页',
        component:list
    },
    ]
})
