import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { List } from 'vant';
Vue.config.productionTip = false
import api from './api'
Vue.use(List);
Vue.prototype.$api = api
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
