<template>
    <div class="wrap">
        <div class="login_form">
            <input type="text" class="cell" placeholder="请输入您的手机号码" v-model="username" />
            <input type="password" class="cell" placeholder="请输入密码" v-model="password" />
            <div class="submit_btn" @click="login">
                登录
            </div>
        </div>
        <div class="tip">
            账单如有疑问，18672978029
        </div>
    </div>
</template>
<script>
    import {Toast} from "vant";
    export default {
        name:"index",
        data(){
            return {
                username:null,
                password:null
            }
        },
        methods:{
            login(){
                if(this.username==null || this.username==='')
                {
                    Toast("登录名不能为空")
                    return
                }
                if(this.password==null || this.password==='')
                {
                    Toast("登录密码不能为空")
                    return;
                }
                this.$api.login({username:this.username,password:this.password}).then(res=>{
                    if(res.data.code===200)
                    {
                        this.$router.push({path:"/home",query:{merchantUserName:this.username}})
                    }else
                    {
                        Toast(res.data.msg)
                    }
                })
            }
        }
    }
</script>
<style scoped>
    .wrap{
        width: 100%;
    }
    .login_form{
        width: 88%;
        margin: 160px 6% 0px 6%;
        height: 240px;
        background: #f0f8fb;
        border-radius: 10px;
        box-sizing: border-box;
        padding-top: 20px;
    }
    .tip{
        position: absolute;
        bottom: 10px;
        width: 100%;
        text-align: center;
        color: white;
    }
    .cell{
        width: 80%;
        height: 40px;
        margin: 20px 10% 0px 10%;
        box-sizing: border-box;
        border:0px;
        border-bottom: 1px solid #bdc1c2;
        background: #f0f8fb;
    }
    .submit_btn{
        height: 40px;
        background: #2e6db5;
        color: white;
        border-radius: 20px;
        width: 80%;
        margin: 40px 10% 0px 10%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
