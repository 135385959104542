<template>
    <div class="wrap">
        <div class="list_box">
            <div class="title">
                商户对账
            </div>
            <div class="scroll_box">
                <van-list class="vant_list" v-model="loading"
                          :finished="finished"
                          finished-text="没有更多了"
                          @load="onLoad">
                    <div class="row" v-for="item in list" :key="item" >
                        <div class="month">账单月份：{{item.reimbursementCycle}}</div>
                        <div class="detail" @click="todetail(item)">查看详情》</div>
                    </div>
                </van-list>
            </div>
        </div>

    </div>
</template>
<script>
    import {Toast} from "vant";
    export default {
        name:'home',
        data(){
            return {
                loading:false,
                finish:false,
                list:[],
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                    merchantUserName: null
                }
            }
        },
        mounted(){
            this.queryParams.merchantUserName = this.$route.query.merchantUserName
        },
        methods:{
            getList(){
                this.$api.getlist(this.queryParams).then(res=>{
                    if(res.data.code===200)
                    {
                        this.loading = false;
                        this.list.push(...res.data.rows)
                        if(this.list.length>=res.data.total)
                        {
                            this.finished = true
                        }
                    }else {
                        Toast(res.msg)
                    }
                })
            },
            onLoad(){
                this.getList();
                this.queryParams.pageNum++;
            },
            todetail(item){
                this.$router.push({path:'/list',query:{merchantNo:item.merchantNo,merchantName:item.merchantName}})
            }
        }
    }
</script>
<style scoped>
    .wrap {
        padding: 20px;
        box-sizing: border-box;
    }

    .list_box{
        border-radius: 10px;
        width: 100%;
        height: 100%;
        background: #f0f8fb;
        overflow: hidden;

    }
    .scroll_box{
        height: calc(100% - 48px);
        overflow-y: scroll;
        padding:20px;
    }
    .title{
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .row{
        height: 48px;
        background: white;
        margin-bottom: 20px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 5px;
        box-sizing: border-box;
    }
    .detail{
        color: #5186c5;
    }
</style>
