/**
* './modules/'：要读取的文件夹
* true：是否读取子目录，不读取设置false
* /\.js$/ 匹配.js类型的文件
* files：获取的api集合
*/
const files = require.context('./modules/', true, /\.js$/)
let apiMaps = {}
files.keys().forEach(key => {
  apiMaps = Object.assign(apiMaps, files(key).default)
  // modules[key.replace(/(\.\/|\.js)/g, '')] = files(key).default
})
export default apiMaps
