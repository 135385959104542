<template>
  <div id="app">
      <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
    #app{
        width: 100%;
        height: 100%;
        display: flex;
    }
    html, body {
        margin: 0;
        padding: 0;
        /*font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;*/
        -webkit-font-smoothing: antialiased;
        width: 100%;
        height: 100%;
        background: linear-gradient(#5186c5, #47bbc4);
    }
    .wrap{
        width: 100%;
        /*height: 100%;*/
        position: relative;
        /*min-height: 100vh;*/
    }
</style>
