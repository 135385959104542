import axios from 'axios'
// 创建 axios 实例
const service = axios.create({

})
// request interceptor
service.interceptors.request.use(config => {
  var token = localStorage.getItem('token')
  var sysPlanId = localStorage.getItem('sysPlanId')
  if (token) {
    config.headers.api_key = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    config.headers.SYS_PLAN_FK = sysPlanId
  }
  if (config.method === 'post' && config.url.indexOf('upload') === -1) {
    // config.data = qs.stringify(config.params)   // 处理content-type weiform表单测试的
    config.data = config.params
    config.params = {}
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

// 定义一个响应拦截器
service.interceptors.response.use(function (config) {
  return config
})
export default service
