import request from '../axios'
// const base = "http://127.0.0.1:8080"
const base = "/prod-api/"
export default {
    login:(query)=>{
        return request({
            url: base+'/mobile/user/login',
            method: 'post',
            params: query
        })
    },
    getlist:(query)=>{
        return request({
                url: base+'/mobile/statements/list',
                method: 'get',
                params: query
              })
    },
    getList1:(query)=>{
        return request({
            url: base+'/mobile/statements/list1',
            method: 'get',
            params: query
        })
    },
}
